import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, CostingGuard, LoginGuard } from './auth-guard.service';
import {
  CustomerResolver,
  InventoryResolver,
  PurchaseOrderResolver,
  ServerHealthResolver,
  SupplierResolver
} from './component-resolvers';
import { RouteGuard } from './route-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' }, // Set route
  {
    path: 'login',
    loadComponent: () => import('./core/index').then(com => com.LoginComponent),
    canActivate: [LoginGuard]
  },
  {
    path: 'reset-password',
    loadComponent: () => import('./core/index').then(com => com.PasswordResetComponent),
    canActivate: [AuthGuard]
  },
  {
    path: 'unauthorized',
    loadComponent: () => import('./core/index').then(com => com.UnauthorizedComponent),
    canActivate: [AuthGuard]
  },
  {
    path: 'connection-lost',
    loadComponent: () => import('./core/index').then(com => com.ConnectionLostComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadComponent: () => import('./core/index').then(com => com.DashboardComponent),
    canActivate: [AuthGuard],
    resolve: { serverHealth: ServerHealthResolver }
  },
  { // reports
    path: '',
    loadChildren: () => import('./core/components/reports/reports.routes').then(routes => routes.ReportRoutes),
    canActivate: [AuthGuard]
  },  
  { // management reports
    path: '',
    loadChildren: () => import('./modules/management-report/management-reports.routes').then(routes => routes.ManagementReportRoutes),
    canActivate: [AuthGuard]
  },
  {
    path: 'hello-world',
    loadComponent: () => import('./core/index').then(com => com.HelloWorldComponent),
    canActivate: [AuthGuard],
    resolve: {}
  },
  { // inventory
    path: '',
    loadChildren: () => import('./core/components/inventory/inventory.routes').then(routes => routes.InventoryRoutes),
    canActivate: [AuthGuard],
    resolve: { data: InventoryResolver, serverHealth: ServerHealthResolver }
  },
  { // supplier
    path: '',
    loadChildren: () => import('./core/components/supplier/supplier.routes').then(routes => routes.SupplierRoutes),
    canActivate: [AuthGuard],
    resolve: { data: SupplierResolver, serverHealth: ServerHealthResolver }
  },
  { // customer
    path: '',
    loadChildren: () => import('./core/components/customer/customer.routes').then(routes => routes.CustomerRoutes),
    canActivate: [AuthGuard],
    resolve: { data: CustomerResolver, serverHealth: ServerHealthResolver }
  },
  { // purchase-order
    path: '',
    loadChildren: () => import('./core/components/purchase-order/po.routes').then(routes => routes.PORoutes),
    canActivate: [AuthGuard],
    resolve: { data: PurchaseOrderResolver, serverHealth: ServerHealthResolver }
  },
  { // grn
    path: '',
    loadChildren: () => import('./core/components/grn/grn.routes').then(routes => routes.GRNRoutes),
    canActivate: [AuthGuard]
  },
  { // material-request
    path: '',
    loadChildren: () => import('./core/components/material-request/material-request.routes').then(routes => routes.MaterialRequestRoutes),
    canActivate: [AuthGuard]
  },
  { // material-return
    path: '',
    loadChildren: () => import('./core/components/material-return/material-return.routes').then(routes => routes.MaterialReturnRoutes),
    canActivate: [AuthGuard]
  },
  { // gin
    path: '',
    loadChildren: () => import('./core/components/gin/gin.routes').then(routes => routes.GINRoutes),
    canActivate: [AuthGuard]
  },
  { // employee 
    path: '',
    loadChildren: () => import('./core/components/employee/employee.routes').then(routes => routes.EmployeeRoutes),
    canActivate: [AuthGuard]
  },
  { // employee-category
    path: '',
    loadChildren: () => import('./core/components/employee-category/employee.routes').then(routes => routes.EmployeeCategoryRoutes),
    canActivate: [AuthGuard]
  },
  { // sales-inquiry
    path: '',
    loadChildren: () => import('./core/components/sales-inquery/sales-inquery.routes').then(routes => routes.SalesInqueryRoutes),
    canActivate: [AuthGuard]
  },
  { // product-development
    path: '',
    loadChildren: () => import('./core/components/product-development/product-development.routes').then(routes => routes.ProductDevelopmentRoutes),
    canActivate: [AuthGuard]
  },
  { // quotation
    path: '',
    loadChildren: () => import('./core/components/quotation/quotation.routes').then(routes => routes.QuotationRoutes),
    canActivate: [AuthGuard]
  },
  { // sales-order
    path: '',
    loadChildren: () => import('./core/components/sales-order/sales-order.routes').then(routes => routes.SalesOrderRoutes),
    canActivate: [AuthGuard]
  },
  { // sales-order
    path: '',
    loadChildren: () => import('./core/components/npd/npd.routes').then(routes => routes.NpdRoutes),
    canActivate: [AuthGuard]
  },
  { // packing
    path: '',
    loadChildren: () => import('./core/components/packing/packing.routes').then(routes => routes.PackingRoutes),
    canActivate: [AuthGuard]
  },
  { // dispatch
    path: '',
    loadChildren: () => import('./core/components/dispatch/dispatch.routes').then(routes => routes.DispatchRoutes),
    canActivate: [AuthGuard]
  },
  { // invoice
    path: '',
    loadChildren: () => import('./core/components/invoice/invoice.routes').then(routes => routes.InvoiceRoutes),
    canActivate: [AuthGuard]
  },
  { // artwork
    path: '',
    loadChildren: () => import('./core/components/artwork/artwork.routes').then(routes => routes.ArtworkRoutes),
    canActivate: [AuthGuard]
  },
  { // customer receipt
    path: '',
    loadChildren: () => import('./modules/customer-receipt/customer-receipt.route').then(routes => routes.CustomerReceiptRoutes),
    canActivate: [AuthGuard],
    // resolve: { data: CustomerResolver, serverHealth: ServerHelthResolver }
  },
  { // customer transaction
    path: '',
    loadChildren: () => import('./modules/customer-transaction/customer-transaction.route').then(routes => routes.CustomerTransactionRoutes),
    canActivate: [AuthGuard],
    // resolve: { data: CustomerResolver, serverHealth: ServerHelthResolver }
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
