import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from "./core/components/login/login.component";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/request.interceptor';
import { ResponseInterceptor } from './core/interceptors/response.interceptor';
import { LoadingComponent } from './core/components/loading/loading.component';
import { NotificationComponent } from './core/components/notification/notification.component';
import { NavBarComponent } from "./core/components/aa_layouts/nav-bar/nav-bar.component";
import { NgxsModule } from '@ngxs/store';
import { SearchState } from './store/search/search.state';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

// import { StoreModule } from '@ngrx/store';
// import { routeReducer } from './state-services/current-route-state/route.reducer';

@NgModule({ declarations: [
        AppComponent,
        LoadingComponent,
        NotificationComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        LoginComponent,
        NavBarComponent,
        NgxsModule.forRoot([SearchState]),
        NgxsLoggerPluginModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot()], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ResponseInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
