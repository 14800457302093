import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../../auth.service';
import { NotificationService } from '../components/notification/notification.service';
import { AuthAPIService } from '../services';
import { UserAuthStorageType } from '../types';
import { SessionStorageService } from '../util/session-storage.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private authAPIService: AuthAPIService,
    private sessionStorageService: SessionStorageService,
  ) { }

  // intercept(
  //   request: HttpRequest<any>,
  //   next: HttpHandler
  // ): Observable<HttpEvent<any>> {
  //   return next.handle(request).pipe(
  //     tap((event: HttpEvent<any>) => {
  //       if (event instanceof HttpResponse) {
  //       }
  //     }),
  //     catchError((error: HttpErrorResponse) => {
  //       console.log(error)
  //       if(error.error.statusCode == 401 || error.status == 401){
  //         this.authService.logout()
  //       }
  //       return throwError(error);
  //     })
  //   );
  // }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const body = event.body;
          if (body && body.response === 500) {
            throw new HttpErrorResponse({
              error: body,
              headers: event.headers,
              status: 500,
              statusText: 'Internal Server Error',
              url: event.url || undefined
            });
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        if (error.status === 401 && !request.url.includes('/auth')) {
          console.log("================ UnAUTHERIZED ===========");
          
          return this.handle401Error(request, next);
        }
        return throwError(error);
      })
    );
  }

  
  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Get the refresh token from authentication service
    const refreshToken = this.authService.getIsAuthenticated()?.refreshToken;
  
    // Prevent multiple login requests in case of concurrent requests
    if (refreshToken && !this.authService.isRefreshingToken) {
      this.authService.isRefreshingToken = true;
  
      // Call the method to refresh the token (assuming authService provides it)
      return this.authAPIService.getAccessToken_usingrefreshToken().pipe(
        switchMap((newToken: any) => {
          // Update the user's token in storage
          const userAuthData: UserAuthStorageType = {
            accessToken: newToken.data.access_token,
            refreshToken: newToken.data.refresh_token,
          };
          this.sessionStorageService.setUserToken(userAuthData);
  
          // Update the Authorization header with the new token
          const authReq = request.clone({
            setHeaders: { Authorization: `Bearer ${newToken.data.access_token}` },
          });
  
          // Retry the original request with the new token
          return next.handle(authReq);
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            // Retry failed due to 401 after token refresh, logout user
            console.log("===================================logout");
            this.authService.logout(); // or handle as needed
          }
          return throwError(error);
        }),
        finalize(() => {
          // Token refresh completed, allow next requests to refresh token
          this.authService.isRefreshingToken = false;
        })
      );
    } else {
      // If another request is already refreshing the token, wait and retry the request
      return this.authService.tokenRefreshed.pipe(
        switchMap(() => next.handle(request))
      );
    }
  }
  
  
}
