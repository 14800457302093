import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, CostingGuard, LoginGuard, MgtAdminGuard } from './auth-guard.service';
import {
  CustomerResolver,
  InventoryResolver,
  PurchaseOrderResolver,
  ServerHealthResolver,
  SupplierResolver
} from './component-resolvers';
import { RouteGuard } from './route-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' }, // Set route
  {
    path: 'login',
    loadComponent: () => import('./core/index').then(com => com.LoginComponent),
    canActivate: [LoginGuard]
  },
  {
    path: 'reset-password',
    loadComponent: () => import('./core/index').then(com => com.PasswordResetComponent),
    canActivate: [AuthGuard]
  },
  {
    path: 'unauthorized',
    loadComponent: () => import('./core/index').then(com => com.UnauthorizedComponent),
    canActivate: [AuthGuard]
  },
  {
    path: 'connection-lost',
    loadComponent: () => import('./core/index').then(com => com.ConnectionLostComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadComponent: () => import('./core/index').then(com => com.DashboardComponent),
    canActivate: [AuthGuard],
    resolve: { serverHealth: ServerHealthResolver }
  },
  {
    path: 'hello-world',
    loadComponent: () => import('./core/index').then(com => com.HelloWorldComponent),
    canActivate: [AuthGuard],
  },
  { // reports
    path: '',
    loadChildren: () => import('./core/components/reports/reports.routes').then(routes => routes.ReportRoutes),
  },
  { // management reports
    path: '',
    loadChildren: () => import('./modules/management-report/management-reports.routes').then(routes => routes.ManagementReportRoutes),
  },

  { // inventory
    path: '',
    loadChildren: () => import('./core/components/inventory/inventory.routes').then(routes => routes.InventoryRoutes),
  },
  { // supplier
    path: '',
    loadChildren: () => import('./core/components/supplier/supplier.routes').then(routes => routes.SupplierRoutes),
  },
  { // customer
    path: '',
    loadChildren: () => import('./core/components/customer/customer.routes').then(routes => routes.CustomerRoutes),
  },
  { // purchase-order
    path: '',
    loadChildren: () => import('./core/components/purchase-order/po.routes').then(routes => routes.PORoutes),
  },
  { // grn
    path: '',
    loadChildren: () => import('./core/components/grn/grn.routes').then(routes => routes.GRNRoutes),
  },
  { // material-request
    path: '',
    loadChildren: () => import('./core/components/material-request/material-request.routes').then(routes => routes.MaterialRequestRoutes),
  },
  { // material-return
    path: '',
    loadChildren: () => import('./core/components/material-return/material-return.routes').then(routes => routes.MaterialReturnRoutes),
  },
  { // gin
    path: '',
    loadChildren: () => import('./core/components/gin/gin.routes').then(routes => routes.GINRoutes),
  },
  { // employee 
    path: '',
    loadChildren: () => import('./core/components/employee/employee.routes').then(routes => routes.EmployeeRoutes),
  },
  { // employee-category
    path: '',
    loadChildren: () => import('./core/components/employee-category/employee.routes').then(routes => routes.EmployeeCategoryRoutes),
  },
  { // sales-inquiry
    path: '',
    loadChildren: () => import('./core/components/sales-inquery/sales-inquery.routes').then(routes => routes.SalesInqueryRoutes),
  },
  { // product-development
    path: '',
    loadChildren: () => import('./core/components/product-development/product-development.routes').then(routes => routes.ProductDevelopmentRoutes),
  },
  { // quotation
    path: '',
    loadChildren: () => import('./core/components/quotation/quotation.routes').then(routes => routes.QuotationRoutes),
  },
  { // sales-order
    path: '',
    loadChildren: () => import('./core/components/sales-order/sales-order.routes').then(routes => routes.SalesOrderRoutes),
  },
  { // npd
    path: '',
    loadChildren: () => import('./core/components/npd/npd.routes').then(routes => routes.NpdRoutes),
  },
  { // packing
    path: '',
    loadChildren: () => import('./core/components/packing/packing.routes').then(routes => routes.PackingRoutes),
  },
  { // dispatch
    path: '',
    loadChildren: () => import('./core/components/dispatch/dispatch.routes').then(routes => routes.DispatchRoutes),
  },
  { // invoice
    path: '',
    loadChildren: () => import('./core/components/invoice/invoice.routes').then(routes => routes.InvoiceRoutes),
  },
  { // artwork
    path: '',
    loadChildren: () => import('./core/components/artwork/artwork.routes').then(routes => routes.ArtworkRoutes),
  },
  { // customer receipt
    path: '',
    loadChildren: () => import('./modules/customer-receipt/customer-receipt.routes').then(routes => routes.CustomerReceiptRoutes),
  },
  { // customer transaction
    path: '',
    loadChildren: () => import('./modules/customer-transaction/customer-transaction.routes').then(routes => routes.CustomerTransactionRoutes),
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
